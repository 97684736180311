import store from '../store.js';
import { getDatabase, ref, off, onValue } from 'firebase/database';

export function listenForTickerTapeItems(building) {
  store.dispatch({
    type: 'LISTEN_TICKERTAPE_BEGIN',
    payload: {}
  });

  const tickerTapeRef = ref(getDatabase(), `/sites/${building}/information/tickertape/`);
  onValue(tickerTapeRef,
    (tickerTapeData) => {
      store.dispatch({
        type: 'LISTEN_TICKERTAPE_COMPLETE',
        payload: tickerTapeData.val()
      });
    },
    (error) => {
      store.dispatch({
        type: 'LISTEN_TICKERTAPE_ERROR',
        error: error.code
      });

    }
  )
}

export function stopListenerForTickerTapeItems(building) {
  off(ref(getDatabase(), `/sites/${building}/information/tickertape/`));
}
