import axios from "axios";
import store from '../store.js'
import { getAuth } from 'firebase/auth';

export function fetchWeather(endpoint, building) {
  store.dispatch({ type: 'FETCH_WEATHER_BEGIN' });


  getAuth().currentUser.getIdToken(true).then(token => {
    axios.get(
      endpoint,
      {
        params: {
          site: building
        },
        headers: {
          'Authorization': "Bearer " + token
        }
      }
    ).then(function (response) {
      if (response.data) {
        store.dispatch({ type: 'FETCH_WEATHER_COMPLETE', payload: response.data });
      } else {
        store.dispatch({ type: 'FETCH_WEATHER_ERROR' });
      }
    }).catch(function (error) {
      store.dispatch({ type: 'FETCH_WEATHER_ERROR' });
    });
  }).catch(function (error) {
    store.dispatch({ type: 'FETCH_WEATHER_ERROR' });
  });
}
