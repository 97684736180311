import { combineReducers } from 'redux';
import authenticationReducer from './authentication';
import configurationReducer from './configuration';
import informationReducer from './information';
import tickertapeReducer from './tickertape';
import weatherReducer from './weather';
import menuReducer from './menu';

export default combineReducers({
  authentication: authenticationReducer,
  configuration: configurationReducer,
  information: informationReducer,
  tickertape: tickertapeReducer,
  weather: weatherReducer,
  menu: menuReducer,
})
