import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class ModalDialog extends React.Component {

  render() {
    const {
      active,
      title,
      bodyItems,
      confirmText,
      cancelText,
      confirmColor,
      cancelColor,
      onConfirm,
      onCancel} = this.props;

      let confirm = confirmText ? confirmText : 'Confirm';
      let cancel = cancelText ? cancelText : 'Cancel';
    return (
      <div>
        <Modal isOpen={active} toggle={this.toggle} className={this.props.className}>
          <ModalHeader>{title}</ModalHeader>
          {
            bodyItems.map(item => {
              return <ModalBody key={item}>{item}</ModalBody>
            })
          }
          <ModalFooter>
            <Button color={confirmColor} onClick={ () => onConfirm() }>{confirm}</Button>{' '}
            <Button color={cancelColor} onClick={ () => onCancel() }>{cancel}</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default ModalDialog;