const initialState = {
  currentState: 'READY',
  data: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case "GET_CONFIG_BEGIN": {
      return {
        ...state,
        currentState: 'PROCESSING',
        data: null
      }
    }
    case "GET_CONFIG_ERROR": {
      return {
        ...state,
        currentState: 'ERROR',
        data: null
      }
    }
    case "GET_CONFIG_COMPLETE": {
      return {
        ...state,
        currentState: 'COMPLETE',
        data: action.payload
      }
    }
    case "AUTH_USER_SILENT_LOGOUT": {
      return initialState;
    }
    default:
      return {
        ...state
      }
  }
}
