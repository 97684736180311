import React, { Component } from 'react';
import PropTypes from 'prop-types';

class TickerTapeItem extends Component {

  render() {
    const { text, height, width } = this.props;
    return (
      <div className="TickerTapeItem" style={{height: height, width: width, overflow: 'hidden'}}>
        {text}
      </div>
    );
  }
}

TickerTapeItem.propTypes = {
  text: PropTypes.string.isRequired
};

export default TickerTapeItem;