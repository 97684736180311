import React, { Component } from 'react';
import WeatherWidget from '../../components/WeatherWidget';
import moment from 'moment';

export class WeatherFiveDayFooterContainer extends Component {

  componentDidMount() {
    const timeout = this.props.timeout ? this.props.timeout : 15000;
    this.timerId = setTimeout(() => {
      if (this.props.onTimeoutComplete) {
        this.props.onTimeoutComplete();
      }
    }, timeout);
  }

  render() {

    const { height, width, forecast } = this.props;

    if (forecast == null) {
      // loading of forecast
      return null;
    }

    return (
      [
        forecast.map(forecastItem => {
          return (
            <WeatherWidget
              key={forecastItem.timestamp}
              height={height}
              width={width / 5}
              text={moment(forecastItem.timestamp).format("ddd")}
              icon={forecastItem.weatherCodeIcon}
              altText={forecastItem.weatherCodeDescription}
              temp={Math.round(forecastItem.temperature)}
            />
          );
        })
      ]
    );
  }

}
