import React from "react";
import PropTypes from "prop-types";

import { Carousel } from "react-responsive-carousel";
import ContentStatic from "../components/ContentStatic";

import "react-responsive-carousel/lib/styles/carousel.min.css";

class ItemCarousel extends React.PureComponent {
  render() {
    const {
      height,
      width,
      interval,
      transitionTime,
      data,
      overlayWidth,
    } = this.props;

    if (data == null || data.length === 0) {
      return null;
    }

    return (
      <Carousel
        showArrows={false}
        showThumbs={false}
        showIndicators={true}
        showStatus={false}
        autoPlay={true}
        interval={interval}
        infiniteLoop={true}
        transitionTime={transitionTime}
        stopOnHover={false}
      >
        {data.map((item) => {
          return (
            <ContentStatic
              key={item.id}
              width={width}
              height={height}
              overlayWidth={overlayWidth}
              title={item.title}
              details={item.description}
              image={item.cardImageUrl}
              imageOnly={item.ds_image_only}
              options={item.signOptions}
            />
          );
        })}
      </Carousel>
    );
  }
}

ItemCarousel.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  interval: PropTypes.number,
  transitionTime: PropTypes.number,
  data: PropTypes.array,
};

export default ItemCarousel;
