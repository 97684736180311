import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ModalDialog from '../components/ModalDialog';

const mapStateToProps = function (state) {
  return {
    menu: state.menu
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
  }, dispatch)
}

class SideBarContainer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentDate: '',
      confirmLogout: false,
      showAbout: false
    }
  }

  onDateChange = newDate => {
    this.setState({ currentDate: newDate });

    // notify parent
    if (this.props.onDateChange) {
      this.props.onDateChange();
    }
  }

  render() {
    return (
      <div style={{ height: 'inherit', padding: 0, paddingRight: 0, paddingLeft: 0, display: 'flex' }}>
        <div style={
          {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
            padding: 0
          }
        }>
          <div className="SideBar" style={{ marginBottom: 20 }}>
            <CurrentDateTime
              onDateChange={this.onDateChange}
              onHourChange={() => this.props.onHourChange()}
              onClick={() => this.setState({ showAbout: true })}
            />
            {this.hasMenuData() ? <h2>Today's Menu</h2> : null}
            <h3>{this.state.currentDate}</h3>
          </div>
          {this.hasMenuData() ? this.renderMenu() : null}
        </div>
        <ModalDialog
          active={this.state.showAbout}
          title='About'
          bodyItems={[
            'Weather data Powered by Tomorrow.io'
          ]}
          confirmText='Logout'
          cancelText='Close'
          confirmColor='danger'
          cancelColor='secondary'
          onConfirm={() => {
            this.setState({ confirmLogout: true });
          }}
          onCancel={() => { this.setState({ showAbout: false }) }}
        />
        <ModalDialog
          active={this.state.confirmLogout}
          title='Confirm Logout'
          bodyItems={[
            'Are you sure you would like to Logout?'
          ]}
          confirmColor='danger'
          cancelColor='secondary'
          onConfirm={() => {
            if (this.props.onLogout) {
              this.props.onLogout();
            }
          }}
          onCancel={() => { this.setState({ confirmLogout: false }) }}
        />
      </div>
    );
  }

  renderMenu = () => {
    if (this.props.menu.data) {
      return (
        <div style={{ paddingTop: 10 }}>
          {
            this.props.menu.data.map(section => {
              return (
                <MenuCategory key={section.sectionKey} section={section.sectionTitle} featuredItem={section.items[0].description} featuredDescription={section.items[0].details} />
              );
            })
          }
        </div>
      );
    }
  }

  hasMenuData = () => {
    return this.props.menu.data && this.props.menu.data.length > 0;
  }
}

class MenuCategory extends React.PureComponent {
  render() {
    return (
      <div className="MenuSection" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingLeft: 10, paddingRight: 10, paddingBottom: 10 }}>
        <h2>{this.props.section}</h2>
        <div style={{ height: 2, width: '100%', backgroundColor: '#ECAF71', marginTop: 5, marginBottom: 5 }} />
        <h1>{this.props.featuredItem}</h1>
        <span style={{ textAlign: 'center' }}>
          {this.props.featuredDescription}
        </span>
      </div>
    );
  }
}

const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
class CurrentDateTime extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      currentTime: new Date(),
    }

    this.timerId = null;
  }

  componentDidMount() {
    this.timerId = setInterval(
      () => this.tick(),
      1000
    );

    if (this.props.onDateChange) {
      this.props.onDateChange(this.state.currentTime.toLocaleDateString("en-US", dateOptions));
    }
  }

  tick() {
    this.setState({
      currentTime: new Date()
    });
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  componentDidUpdate(prevProps, prevState) {
    let prevDateTime = prevState.currentTime;
    let newDateTime = this.state.currentTime;

    if (prevDateTime && newDateTime) {
      if (prevDateTime.getHours() !== this.state.currentTime.getHours()) {
        if (this.props.onHourChange) {
          this.props.onHourChange();
        }
      }

      if (prevDateTime.getDay() !== this.state.currentTime.getDay()) {
        if (this.props.onDateChange) {
          this.props.onDateChange(this.state.currentTime.toLocaleDateString("en-US", dateOptions));
        }
      }
    }
  }

  render() {

    return (
      <h1 onClick={this.onClick}>{this.state.currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</h1>
    );
  }

  onClick = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SideBarContainer)