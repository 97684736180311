import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { authStateMonitor, userLogin } from '../actions/authentication';
import { startListeningConfiguration, stopListeningConfiguration } from '../actions/configuration';
import LoadingOverlayContainer from '../containers/LoadingOverlay';
import ContentContainer from '../containers/Content';
import LoginContainer from '../containers/Login';

const mapStateToProps = function(state){
  return {
    authentication: state.authentication,
    configuration: state.configuration
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
  }, dispatch)
}

class DigitalBoardScreen extends Component {

  constructor(props) {
    super(props);

    this.state = {
      currentState: 'INITIALIZING'
    };

    this._isReadyForContent.bind(this);
    this._isProcessing.bind(this);
  }

  /* Utility Methods */
  _isReadyForContent() {
    if (this.props.authentication.loggedIn) {
      if (this.props.configuration.currentState === 'COMPLETE' && this.props.configuration.data) {
        return true;
      }
    }
    return false;
  }

  _isProcessing() {
    return this.props.authentication.initializing || 
      this.props.authentication.processing ||
      (this.props.authentication.loggedIn && 
        (this.props.configuration.currentState === 'READY' || this.props.configuration.currentState === 'PROCESSING'));
  }

  /* LifeCycle Methods */
  componentWillMount() {
    if (this.props.authentication.initializing) {
      authStateMonitor();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.authentication.loggedIn && this.props.authentication.user && this.props.authentication.user.building) {
      if (this.props.configuration.currentState === 'READY') {
        startListeningConfiguration(this.props.authentication.user.building);
      }
    }

    if (prevProps.authentication.loggedIn && !this.props.authentication.loggedIn) {
      stopListeningConfiguration(prevProps.authentication.user.building);
    }
  }

  componentWillUnmount() {
    stopListeningConfiguration(this.props.authentication.user.building);
  }

  /* Rendering Methods */
  _renderContent = () => {
    if (this._isReadyForContent()) {
      return (
        <ContentContainer />
      );
    } else if (!this.props.authentication.loggedIn && !this.props.authentication.initializing){
      return (
        <LoginContainer
          onLogin={ (username, password) => userLogin(username, password) }
          error={this.props.authentication.error}
        />
      );
    }
  };

  render() {
    return (
      <div className="DigitalScreen">
        {this._renderContent()}
        <LoadingOverlayContainer show={this._isProcessing()}/>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(DigitalBoardScreen)
