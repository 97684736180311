import store from '../store.js';

import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { getDatabase, ref, get } from 'firebase/database';

export function authStateMonitor() {
  getAuth().onAuthStateChanged(function (user) {
    if (user) {
      const userRef = ref(getDatabase(), `/Users/${user.uid}`);
      get(userRef).then((userData) => {
        const superUserRef = ref(getDatabase(), `/superusers/${user.uid}`);
        get(superUserRef)
          .then((superUserData) => {

            // get the digital board building assigned to this superuser, or fail out
            const building = superUserData.val().ds_site;

            store.dispatch({
              type: 'AUTH_USER_LOGIN_SUCCESS',
              user: {
                email: user.email,
                building: building
              }
            })
          })
          .catch((error) => {
            console.log(error);
            getAuth().signOut();
          })
      }).catch((error) => {
        getAuth().signOut();
      });
    }
    else {
      // notify we have silently logged out
      store.dispatch({
        type: 'AUTH_USER_SILENT_LOGOUT',
        payload: {}
      });
    }
  });

}

export function userLogout() {
  getAuth().signOut().then(function () {

    // dispatch error
    store.dispatch({
      type: 'AUTH_USER_SILENT_LOGOUT',
      payload: {}
    });

  });
}

export function userLogin(email, password) {

  store.dispatch({
    type: 'AUTH_USER_LOGIN_PROCESSING',
    payload: {}
  });

  // attempt to sign in
  signInWithEmailAndPassword(getAuth(), email.trim(), password).then(function () {
    // handle auth flow in the state manager already listening for auth state changes
    console.log("👋")
  }).catch((_) => {
    console.log("Error logging in")
    // notify error
    store.dispatch({
      type: 'AUTH_USER_LOGIN_ERROR',
      payload: {}
    });

  });

}
