import React, { PureComponent, createElement } from "react";
import ReactMarkdown from "react-markdown";

class ContentStatic extends PureComponent {
  render() {
    const {
      width,
      height,
      title,
      details,
      image,
      imageOnly,
      overlayWidth,
      options,
    } = this.props;
    // determine the appropriate defaults if sign options doesn't exist
    const optionOverlay =
      options && options.overlay != null ? options.overlay : "half";
    const optionBGImage =
      options && options.backgroundImage != null
        ? options.backgroundImage
        : true;
    const fullImage =
      options && options.fullImage != null ? options.fullImage : false;
    const optionBGColor =
      options && options.backgroundColor != null
        ? options.backgroundColor
        : "#000000";

    return (
      <div
        style={{
          zIndex: 2,
          position: "relative",
          display: "block",
          backgroundColor: optionBGImage ? "#000000" : optionBGColor,
          width: width,
          height: height,
        }}
      >
        {optionOverlay === "none" ? null : (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: optionOverlay === "full" ? width : overlayWidth,
              height: height,
              backgroundColor: "rgba(0, 0, 0, 0.55)",
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h1
              style={{
                paddingLeft: 20,
                paddingRight: 20,
                backgroundColor: "rgba(256, 256, 256, 0.2)",
              }}
            >
              {title}
            </h1>
            <div
              style={{
                display: "flex",
                flexDirection: 'column',
                flex: 1,
                width: "inherit",
                paddingLeft: 60,
                paddingRight: 60,
                justifyContent:
                  optionOverlay === "full" ? "center" : "flex-start",
                alignItems: optionOverlay === "full" ? "center" : "flex-start",
                margin: 20,
                marginLeft: 0,
              }}
            >
              <ReactMarkdown
                children={details}
                disallowedElements={['img']}
                components={{
                  p: (props) => <ParagraphWithAlignment {...props} />,
                  h1: (props) => <HeadingWithAlignment {...props} alignment={optionOverlay === "full" ? "center" : "left"} />,
                  h2: (props) => <HeadingWithAlignment {...props} alignment={optionOverlay === "full" ? "center" : "left"} />,
                  h3: (props) => <HeadingWithAlignment {...props} alignment={optionOverlay === "full" ? "center" : "left"} />,
                  h4: (props) => <HeadingWithAlignment {...props} alignment={optionOverlay === "full" ? "center" : "left"} />,
                  ol: ListWithPadding,
                  ul: ListWithPadding,
                }}
              />
            </div>
          </div>
        )}
        {fullImage ? (
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              width: width - overlayWidth,
              height: height,
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              padding: 20,
              flex: "0 0 auto",
              objectFit: "scale-down",
            }}
          >
            <img
              alt=""
              style={{
                maxWidth: (width - overlayWidth) * 0.9,
                width: "auto",
                objectFit: "contain",
              }}
              src={image}
            />
          </div>
        ) : null}

        {optionBGImage ? (
          <img
            alt=""
            style={{
              zIndex: 1,
              width: width,
              height: height,
              objectFit: "cover",
              opacity: fullImage ? 0.2 : 1,
            }}
            src={image}
          />
        ) : null}
      </div>
    );
  }
}

function ListWithPadding({ node, children }) {
  return createElement(
    "div",
    { style: { padding: 20 } },
    createElement(node.tagName, {}, children)
  );
}

function ParagraphWithAlignment({ children }) {
  return createElement(
    "p",
    { style: { textAlign: "left" } },
    children
  );
}

function HeadingWithAlignment({ node, children, alignment, size }) {
  return createElement(
    `${node.tagName}`,
    {
      style: { textAlign: alignment ? alignment : "left", padding: 0, margin: 0 },
    },
    children
  );
}

export default ContentStatic;
