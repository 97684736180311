import React, { Component } from 'react';
import PropTypes from 'prop-types';

class IconWidget extends Component {

  render() {

    const {
      width,
      height,
      image,
    } = this.props;

    let imageWidth = width*0.8;
    let margin = (width - (imageWidth))/2;

    return (
      <div 
        className="IconWidget" 
        style={{
          maxHeight: height,
          height: height,
          maxWidth: width
        }}
      >
        <img 
        alt='' 
        style={{
          width: imageWidth, 
          objectFit: 'contain',
          marginLeft: margin
        }} 
        src={image} />
      </div>
    );
  }
}

IconWidget.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  image: PropTypes.string,
  range: PropTypes.shape({
    low: PropTypes.string,
    high: PropTypes.string
  })
};

export default IconWidget;