import React, { Component } from 'react';
import PropTypes from 'prop-types';

class WeatherWidget extends Component {

  render() {

    const {
      width,
      height,
      text,
      icon,
      altText,
      temp,
      current
    } = this.props;

    const imageDim = (height + 20) / 2;
    return (
      <div className="WeatherWidget" style={{ minHeight: height, maxHeight: height, maxWidth: width, flex: 1 }}>
        <img alt={altText} style={{ height: imageDim, width: imageDim }} src={icon} />
        <h1>{text}</h1>
        <h2>{temp}</h2>
      </div>
    );
  }

}

WeatherWidget.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  text: PropTypes.string.isRequired,
  image: PropTypes.string,
  temp: PropTypes.number,
  range: PropTypes.shape({
    low: PropTypes.string,
    high: PropTypes.string
  })
};

export default WeatherWidget;