const initialState = {
  state: 'READY',
  data: {
    current: null,
    forecast: []
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_WEATHER_BEGIN": {
      return {
        ...state,
        state: 'PROCESSING',
      }
    }
    case "FETCH_WEATHER_ERROR": {
      return {
        state: 'ERROR',
        data: {
          current: null,
          forecast: []
        }
      }
    }
    case "FETCH_WEATHER_COMPLETE": {
      return {
        state: 'READY',
        data: _processWeatherPayload(action.payload)
      }
    }
    case "AUTH_USER_SILENT_LOGOUT": {
      return initialState;
    }
    default:
      return {
        ...state
      }
  }
}

function _processWeatherPayload(payload) {
  return {
    current: payload.current,
    weekly: payload.weekly
  }
}
