import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { userLogout } from "../actions/authentication";
import {
  listenForInformationCards,
  stopListenerForInformationCards,
} from "../actions/information";
import {
  listenForTickerTapeItems,
  stopListenerForTickerTapeItems,
} from "../actions/tickertape";
import {
  startListeningDailyMenu,
  stopListeningDailyMenu,
} from "../actions/menu";
import { fetchWeather } from "../actions/weather";

import ItemCarousel from "../components/ItemCarousel";
import Footer from "./footer/Footer";
import SideBarContainer from "./SideBar";

const mapStateToProps = function (state) {
  return {
    authentication: state.authentication,
    configuration: state.configuration,
    information: state.information,
    tickertape: state.tickertape,
    weather: state.weather,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

class ContentContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      footer: 0,
    };
  }

  componentWillMount() {
    if (this.props.configuration.data === null) {
      console.error("missing configuration data");
      return;
    }

    this.fetchLatestWeather();
    listenForInformationCards(this.props.authentication.user.building);
    listenForTickerTapeItems(this.props.authentication.user.building);

    if (this.props.configuration.data.feature_flag_menu) {
      startListeningDailyMenu(this.props.authentication.user.building);
    }
  }

  fetchLatestWeather = () => {
    if (this.props.configuration.data.feature_flag_weather) {
      fetchWeather(
        this.props.configuration.data.weather_api,
        this.props.authentication.user.building
      );
    }
  };

  componentWillUnmount() {
    stopListenerForInformationCards(this.props.authentication.user.building);
    stopListenerForTickerTapeItems(this.props.authentication.user.building);
    stopListeningDailyMenu(this.props.authentication.user.building);
  }

  render() {
    const containerWidth = window.innerWidth;
    const containerHeight = window.innerHeight;
    const footerHeight = containerWidth * 0.1; // handle different widths
    const footerWidth = containerWidth;
    const contentHeight = containerHeight - footerHeight;
    const contentWidth = containerWidth * 0.82;
    const sideBarWidth = containerWidth * 0.18;
    const contentOverlayWidth = containerWidth * 0.5;

    const weatherFooterTimeout = this.props.configuration.data.weatherTimeout
      ? this.props.configuration.data.weatherTimeout
      : 15000;
    const tickerTapeInterval = this.props.configuration.data.tickerTapeInterval
      ? this.props.configuration.data.tickerTapeInterval
      : 15000;
    const isWeatherEnabled = this.props.configuration.data.feature_flag_weather;
    return (
      <div className="ContentContainer">
        <div
          className="ContentBody"
          style={{
            height: contentHeight,
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ width: contentWidth, height: contentHeight }}>
            <ItemCarousel
              interval={this.props.configuration.data.autoplayInterval}
              transitionTime={this.props.configuration.data.autoplayTransition}
              height={contentHeight}
              width={contentWidth}
              overlayWidth={contentOverlayWidth}
              data={this.props.information.infoCards}
            />
          </div>
          <div
            style={{
              position: "absolute",
              backgroundColor: "transparent",
              opacity: 1,
              width: 45,
              height: contentHeight,
              right: sideBarWidth,
            }}
          >
            <svg
              width="50"
              height={contentHeight}
              viewBox={`0 0 50 ${contentHeight}`}
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Page-1" fill="none" fillRule="evenodd">
                <path
                  d={`
                M0,0 
                C40,${contentHeight * 0.2} 40,${contentHeight * 0.35} 40,${contentHeight * 0.4
                    } C40,${contentHeight * 0.6} 40,${contentHeight * 0.75
                    } 0,${contentHeight} L55,${contentHeight} L55,0 L0,0 Z`}
                  id="Rectangle"
                  fill="white"
                />
              </g>
            </svg>
          </div>
          <div style={{ width: sideBarWidth, height: contentHeight }}>
            <SideBarContainer
              onHourChange={() => {
                this.fetchLatestWeather();
              }}
              onDateChange={() => {
                stopListeningDailyMenu();
                if (this.props.configuration.data.feature_flag_menu) {
                  startListeningDailyMenu(
                    this.props.authentication.user.building
                  );
                }
              }}
              onLogout={() => {
                userLogout();
              }}
            />
          </div>
        </div>
        <div
          className="ContentFooter"
          style={{
            height: footerHeight,
            maxHeight: footerHeight,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          <div
            className="ContentFooterOverlay"
            style={{
              position: "absolute",
              bottom: 0,
              height: footerHeight - 5,
              maxHeight: footerHeight - 5,
              width: footerWidth,
            }}
          >
            <Footer
              width={footerWidth}
              height={footerHeight - 5}
              forecast={this.props.weather.data.weekly}
              forecastTimeout={weatherFooterTimeout}
              isWeatherEnabled={isWeatherEnabled}
              currentWeather={this.props.weather.data.current}
              tickerTapeItems={this.props.tickertape.items}
              tickerTapeInterval={tickerTapeInterval}
            />
          </div>
        </div>
      </div>
    );
  }
}

ContentContainer.propTypes = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentContainer);
