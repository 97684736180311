import React, { Component } from 'react';
import Modal from 'react-modal';
import { ClipLoader } from 'react-spinners';

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};

class LoadingOverlayContainer extends Component {
  render() {
    const { show } = this.props;
    return (
      <Modal isOpen={show} style={customStyles} ariaHideApp={false}>
        <ClipLoader />
      </Modal>
    );
  }
}
export default LoadingOverlayContainer
