const initialState = {
  currentState: "READY",
  infoCards: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "LISTEN_INFORMATION_BEGIN": {
      return {
        ...state,
        currentState: "PROCESSING",
      };
    }
    case "LISTEN_INFORMATION_COMPLETE": {
      return {
        ...state,
        currentState: "COMPLETE",
        infoCards: _processInfoCardsFirebase(action.payload),
      };
    }
    case "LISTEN_INFORMATION_ERROR": {
      return {
        ...state,
        currentState: "ERROR",
      };
    }
    case "AUTH_USER_SILENT_LOGOUT": {
      return initialState;
    }
    default:
      return {
        ...state,
      };
  }
};

function _processInfoCardsFirebase(payload) {
  // payload.items contain items
  // payload.position contains positional information

  let infoCards = [];

  // retrieve position data
  let posObj = payload.position || {};

  for (var key in payload.items) {
    var infoCard = payload.items[key];

    let pData = posObj[key] || {};
    let position = pData.position || 0;

    if (infoCard.published_sign) {
      infoCards.push({
        id: key,
        title: infoCard.title,
        description: infoCard.description,
        cardImageUrl: infoCard.imageUrl,
        ds_image_only: infoCard.ds_image_only,
        signOptions: infoCard.signOptions,
        position: position,
      });
    }
  }

  return infoCards.sort(_sortInformation);
}

function _sortInformation(a, b) {
  // primary sort on position
  if (a.position < b.position) {
    return -1;
  } else if (a.position > b.position) {
    return 1;
  }

  // secondary sort on id
  if (a.id < b.id) {
    return 1;
  } else if (a.id > b.id) {
    return -1;
  }
  return 0;
}
