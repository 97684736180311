import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { initializeApp } from 'firebase/app';
import store from './store.js'
import './App.css';
import DigitalBoard from './screens/DigitalBoard.js';
import FirebaseConfig from './FirebaseConstants';

initializeApp(FirebaseConfig);

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <DigitalBoard />
      </Provider>

    );
  }
}

export default App;
