import store from '../store.js';
import { getDatabase, ref, onValue, off } from 'firebase/database';

export function listenForInformationCards(building) {
  store.dispatch({
    type: 'LISTEN_INFORMATION_BEGIN',
    payload: {}
  })

  const informationRef = ref(getDatabase(), `/sites/${building}/information/screen/`);

  onValue(informationRef, informationData => {
    store.dispatch({
      type: 'LISTEN_INFORMATION_COMPLETE',
      payload: informationData.val()
    });
  }, error => {
    store.dispatch({
      type: 'LISTEN_INFORMATION_ERROR',
      error: error.code
    });

  });
}

export function stopListenerForInformationCards(building) {
  off(ref(getDatabase(), `/sites/${building}/information/screen/`));
}
