const initialState = {
  currentState: 'READY',
  data: null,
  error: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case "MENU_FETCHING": {
      return {
        currentState: 'FETCHING',
        data: null,
        error: null
      }
    }
    case "MENU_COMPLETE": {
      return {
        currentState: 'READY',
        data: _processMenu(action.dailyPayload, action.sectionPayload),
        error: null
      }
    }
    case "MENU_ERROR": {
      return {
        currentState: 'FETCHING',
        data: null,
        error: action.error
      }
    }
    default:
      break;
  }

    return state;
}

function _processMenu(dailyPayload, sectionPayload) {
  let menu = [];

  if (dailyPayload) {
    for (var sectionKey in sectionPayload) {
      if (dailyPayload[sectionKey]) {
        menu.push({
          sectionKey: sectionKey,
          sectionTitle: sectionPayload[sectionKey].title,
          order: sectionPayload[sectionKey].order,
          items: dailyPayload[sectionKey].items
        })
      }
    }
  }
  return menu.sort(_compareSections);
}

function _compareSections(a, b) {
  if (a.order > b.order) {
    return 1;
  } else if (a.order < b.order) {
    return -1;
  }
  return 0;
}
