import store from '../store.js'
import moment from 'moment';

import { get, getDatabase, off, onValue, ref } from 'firebase/database';

const day_map = {
  1: "mon",
  2: "tues",
  3: "wed",
  4: "thurs",
  5: "fri",
  6: "sat",
  7: "sun",
};

export function stopListeningDailyMenu(building) {

  // determine the day from the day of the week
  const day = day_map[moment().isoWeekday()];

  // determine start of the week based on the current day of the week
  const startOfWeek = moment(moment().subtract(moment().isoWeekday() - 1, 'days')).format("YYYY/M/D");

  off(ref(getDatabase(), `/sites/${building}/food/menu/weekly/${startOfWeek}/${day}`));
}

export function startListeningDailyMenu(building) {

  store.dispatch({
    type: 'MENU_FETCHING',
    payload: {}
  });

  // determine the day from the day of the week
  const day = day_map[moment().isoWeekday()];

  // determine start of the week based on the current day of the week
  const startOfWeek = moment(moment().subtract(moment().isoWeekday() - 1, 'days')).format("YYYY/M/D");

  const dailyMenuRef = ref(getDatabase(), `/sites/${building}/food/menu/weekly/${startOfWeek}/${day}`);
  onValue(dailyMenuRef,
    (weeklyData) => {
      const sectionsRef = ref(getDatabase(), `/sites/${building}/food/menu/sections`);
      get(sectionsRef)
        .then((sections) => {
          store.dispatch({
            type: 'MENU_COMPLETE',
            dailyPayload: weeklyData.val(),
            sectionPayload: sections.val()
          });
        }).catch((error) => {
          throw error;
        })
    },
    (error) => {
      store.dispatch({
        type: 'MENU_ERROR',
        error: 'error-section-data'
      });
    }
  )
}
