import React, { Component, Fragment } from 'react';
import WeatherWidget from '../../components/WeatherWidget';
import IconWidget from '../../components/IconWidget';
import TickerTapeItem from '../../components/TickerTapeItem';

export class TickerTapeFooterContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      currentTapeIndex: 0
    }
  }

  componentDidMount() {
    const { interval } = this.props;
    this.timerId = setInterval(
      () => this.tick(),
      interval ? interval : 15000
    );
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }

  tick() {
    if (this.props.tickerTapeItems && this.props.tickerTapeItems.length - 1 > this.state.currentTapeIndex) {
      this.setState({
        currentTapeIndex: this.state.currentTapeIndex + 1
      });
    } else if (this.state.currentTapeIndex !== 0 || this.state.currentTapeIndex === this.props.tickerTapeItems.length - 1) {
      this.setState({
        currentTapeIndex: 0
      });

      if (this.props.onCompleteItems) {
        this.props.onCompleteItems();
      }
    } else {
      if (this.props.onCompleteItems) {
        this.props.onCompleteItems();
      }
    }
  }

  render() {

    const { height, width, todayForecast, tickerTapeItems, weatherEnabled } = this.props;
    const temp = todayForecast ? Math.round(todayForecast.temperature) : null;
    const weatherIcon = todayForecast ? todayForecast.weatherCodeIcon : null;
    const weatherCodeDescription = todayForecast ? todayForecast.weatherCodeDescription : "";

    let bTickerTapeExists = tickerTapeItems && tickerTapeItems[this.state.currentTapeIndex];
    const tickerTapeText = bTickerTapeExists ? tickerTapeItems[this.state.currentTapeIndex].description : 'Have a great day!';

    const widthEdges = width / 5;

    return (
      [
        <Fragment key='dailyForecast'>
          {
            weatherEnabled
              ?
              <WeatherWidget
                current
                height={height}
                width={widthEdges}
                text='Now'
                icon={weatherIcon}
                altText={weatherCodeDescription}
                temp={temp}
              />
              :
              null
          }
        </Fragment>,
        <div key='tickerTapeContainer' className="TickerTapeContainer" style={{ flex: 1, alignItems: 'center', maxHeight: height, height: height }}>
          <TickerTapeItem text={tickerTapeText} height={height} width={width - widthEdges * 2} />
        </div>,
        <IconWidget
          key='iconWidget'
          height={height}
          width={widthEdges}
          image='https://firebasestorage.googleapis.com/v0/b/wellings-dev.appspot.com/o/wlw.png?alt=media&token=4323748c-4b16-4dea-88cb-4a6e556337c0'
        />
      ]
    );
  }

}
