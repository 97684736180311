import store from '../store.js';

import { getDatabase, ref, onValue, off } from 'firebase/database';

var mutex = false;
export function startListeningConfiguration(building) {
  if (mutex) {
    return;
  }
  mutex = true;

  store.dispatch({
    type: 'GET_CONFIG_BEGIN',
    payload: null
  });

  const buildingRef = ref(getDatabase(), `/config/sites/${building}/ds`);

  off(buildingRef);
  onValue(buildingRef, (buildingConfig) => {
    store.dispatch({
      type: 'GET_CONFIG_COMPLETE',
      payload: buildingConfig.val()
    })
  }, error => {
    store.dispatch({
      type: 'GET_CONFIG_ERROR',
      payload: null
    });
  })

}

export function stopListeningConfiguration(building) {
  off(ref(getDatabase(), `/config/sites/${building}/ds`));

  mutex = false; // reset mutex
}