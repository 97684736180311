const initialState = {
  currentState: 'READY',
  items: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case "LISTEN_TICKERTAPE_BEGIN": {
      return {
        ...state,
        currentState: 'PROCESSING'
      }
    }
    case "LISTEN_TICKERTAPE_COMPLETE": {
      return {
        ...state,
        currentState: 'COMPLETE',
        items: _processTickerTape(action.payload)
      }
    }
    case "LISTEN_TICKERTAPE_ERROR": {
      return {
        ...state,
        currentState: 'ERROR'
      }
    }
    case "AUTH_USER_SILENT_LOGOUT": {
      return initialState;
    }
    default:
      return {
        ...state
      }
  }
}

function _processTickerTape(payload) {
  let items = [];

  if (payload) {

    // retrieve position data
    let posObj = payload.position || {};

    for (var key in payload.items) {
      var item = payload.items[key];
  
      let pData = posObj[key] || {};
      let position = pData.position || 0;
  
      if (item.published_sign) {
        items.push({
          id: key,
          description: item.description,
          position: position
        });
      }
    }
  }

  return items.sort(_sortTickerTape);
}

function _sortTickerTape(a, b) {

  // primary sort on position
  if (a.position < b.position) {
    return -1;
  } else if (a.position > b.position) {
    return 1;
  }

  // secondary sort on id
  if (a.id < b.id) {
    return 1;
  } else if (a.id > b.id) {
    return -1;
  }
  return 0;
}