import React, { Component } from 'react';
import '../App.css';

class LoginContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: ''
    }
  }

  _handleSubmit = event => {
    const { onLogin } = this.props;
    if (onLogin) {
      onLogin(this.state.username, this.state.password);
    }

    event.preventDefault();
  };

  render() {

    return (
      <div className="LoginContainer">
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <div>
            <form onSubmit={this._handleSubmit}>
              <label>
                Email:<br/>
                <input type="text" name="email" value={this.state.username} onChange={ event => this.setState({username: event.target.value})}/>
              </label>
              <br/>
              <label>
                Password:<br/>
                <input type="password" name="password" value={this.state.password} onChange={ event => this.setState({password: event.target.value})}/>
              </label>
              <br/>
              <input type="submit" value="Login" />
            </form>
          </div>
        </div>
        <div className="Error" style={{paddingTop: 10, textAlign: 'center'}}>
          {this.props.error ? 'Incorrect username or password' : null}
        </div>
      </div>
    );
  }
}
export default LoginContainer
