import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { WeatherFiveDayFooterContainer } from './Weather';
import { TickerTapeFooterContainer } from './TickerTape';

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      footer: 0
    };
  }

  render() {

    const {
      isWeatherEnabled,
      width,
      height,
      forecast,
      forecastTimeout,
      currentWeather,
      tickerTapeItems,
      tickerTapeInterval
    } = this.props;

    if (this.state.footer === 0 && isWeatherEnabled) {
      return (
        <WeatherFiveDayFooterContainer
          width={width} 
          height={height} 
          forecast={forecast}
          timeout={forecastTimeout}
          onTimeoutComplete={ () => this.setState({footer: 1})}
        />
      );
    } else {
      return (
        <TickerTapeFooterContainer
          width={width}
          height={height}
          weatherEnabled={isWeatherEnabled}
          todayForecast={currentWeather}
          tickerTapeItems={tickerTapeItems}
          interval={tickerTapeInterval}
          onCompleteItems={ () => {
            if (isWeatherEnabled) {
              this.setState({footer: 0})
            }
          }}
        />
      );
    }
  }
}

Footer.propTypes = {
  isWeatherEnabled: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  forecast: PropTypes.array,
  forecastTimeout: PropTypes.number,
  currentWeather: PropTypes.object,
  tickerTapeItems: PropTypes.array,
  tickerTapeInterval: PropTypes.number
}

export default Footer;